/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useEffect, useState } from "react";

import "./App.scss";
// @ts-ignore
import { EventEmitter } from "@roambee/client-utility";
import Assets from "./pages/Assets/Assets";

function App() {
	const [isWide, setWide] = useState(true);

	useEffect(() => {
		EventEmitter.addListener("toggleSideNav", (eventData) => {
			setWide(eventData);
		});

		return () => {
			EventEmitter.removeListener("toggleSideNav", (eventData) => {
				setWide(eventData);
			});
		};
	}, []);

	return (
		<section id="main-layout" className={isWide ? "sidenav-normal" : "sidenav-small"}>
			<section id="container-layout">
				<Assets />
			</section>
		</section>
	);
}

export default App;
