/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
import { useEffect, useMemo, useState } from "react";
// @ts-ignore
import { DataTable, DrawerPanel } from "@roambee/client-styleguide";
// @ts-ignore
import { API, EventEmitter, generateQueryParams, getAuthUser, formatCustomDateTime } from "@roambee/client-utility";
import { Asset_Table_Columns, Asset_Table_Filter_Operations } from "../configs/AssetTableConfig";
import { API_PATH } from "../configs/APIConfig";
import { useSearchParams } from "react-router-dom";

let tableConfiguration: any = localStorage.getItem("asset_table");
if (tableConfiguration && tableConfiguration.length) {
	tableConfiguration = JSON.parse(tableConfiguration);
}

const parseFilterString = (filterString) => {
	if (filterString && filterString.length) {
		return filterString.split(" and ").map((filter) => {
			if (filter) {
				const [id, operation, value] = filter.match(/(\w+)\s(\w+)\s'(.+)'/).slice(1);
				return { id, value };
			}
		});
	} else {
		return null;
	}
};

function AssetTable() {
	let urlFilters: any = {};
	const [searchParams] = useSearchParams();
	if (searchParams && searchParams.get("filter")) {
		urlFilters = JSON.parse(searchParams.get("filter"));
	}
	const [data, setData] = useState({ data: [], page_num: 1, page_size: 10, statusCode: 0, total: 0 });
	const [open, setOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const [columnFilters, setColumnFilters] = useState(parseFilterString(urlFilters?.filter) || parseFilterString(tableConfiguration?.filter) || [{ id: "asset_type", value: "0" }]);
	const [columnFilterModes, setColumnsFilterModes] = useState([]);
	const [sorting, setSorting] = useState(urlFilters?.sort || tableConfiguration?.sort || []);
	const [pagination, setPagination] = useState({
		pageIndex: urlFilters?.offset || tableConfiguration?.offset || 0,
		pageSize: urlFilters?.size || tableConfiguration?.size || 10
	});

	const [filterConfigs, setFilterConfigs] = useState([
		{
			label: "All",
			variant: "primary",
			size: "small",
			onClick: () => handleFilterClick("All")
		},
		{
			label: "Active TOR",
			variant: "secondary",
			size: "small",
			onClick: () => handleFilterClick("Active TOR")
		}
		// Add more filter configurations as needed
	]);

	const [toolbarConfigs, setToolbarConfigs] = useState([
		{
			label: "All filters",
			variant: "secondary",
			size: "small",
			onClick: () => setOpen(true)
		},
		{
			label: "Share",
			variant: "secondary",
			size: "small",
			onClick: () => navigator.clipboard.writeText(window.location.origin + window.location.pathname + `?filter=${localStorage.getItem("asset_table")}`)
		}
	]);

	const user = getAuthUser() || {};
	const customAssetFields = user?.account?.data?.custom_asset_fields || [];
	const customFieldColumns = customAssetFields.map((element) => {
		if (element.entity_type === "asset") {
			if (element.label.toLowerCase().includes("date")) {
				return {
					accessorKey: `custom_fields.${element.label.toLowerCase()}`,
					header: element.placeholder,
					isSortable: true,
					isSearchable: false,
					Cell: ({ renderedCellValue }) => formatCustomDateTime(renderedCellValue ? renderedCellValue[0] : null, "LLL dd, yyyy hh:mm")
				};
			} else {
				return {
					accessorKey: `custom_fields.${element.label.toLowerCase()}`,
					header: element.placeholder,
					isSortable: true,
					isSearchable: false,
					Cell: ({ renderedCellValue }) => (renderedCellValue ? renderedCellValue : "-")
				};
			}
		}
	});
	const columns = useMemo(() => [...Asset_Table_Columns, ...customFieldColumns], [customFieldColumns]);

	useEffect(() => {
		setLoading(true);
		const params = {
			offset: pagination.pageIndex || 0,
			size: pagination.pageSize || 10,
			fields: "all",
			filter: generateFilterString(columnFilters),
			sort: sorting
		};
		const queryString = generateQueryParams(params);
		API("GET", `${API_PATH.ASSETS}?${queryString}`)
			.then((result) => {
				setData(result);
				setLoading(false);
			})
			.catch((error) => {
				if (error.status === 404 && error.message) {
					// eslint-disable-next-line no-console
					console.error(error.message);
				} else {
					// eslint-disable-next-line no-console
					console.error("Error:", error);
					EventEmitter.emit("showSnackbar", {
						id: "error",
						leftIcon: true,
						message: error?.message || "Something went wrong!",
						variant: "error",
						ariaLabel: "close button",
						position: "top-right"
					});
				}
				setLoading(false);
			});
		localStorage.setItem("asset_table", JSON.stringify(params));
	}, [columnFilters, pagination, sorting]);

	const handleFilterClick = (label) => {
		// Update variant in filterConfigs
		const updatedFilters = filterConfigs.map((filter) => ({
			...filter,
			variant: filter.label === label ? "primary" : "secondary"
		}));
		setFilterConfigs(updatedFilters);

		// Update columnFilters based on label
		if (label === "All") {
			// Remove filter with id: "tor_status"
			setColumnFilters((prevFilters) => prevFilters.filter((filter) => filter.id !== "tor_status"));
		} else {
			// Check if there is already a filter with id: "tor_status"
			const existingFilterIndex = columnFilters.findIndex((filter) => filter.id === "tor_status");

			if (existingFilterIndex !== -1) {
				// Update existing filter with id: "tor_status"
				const updatedColumnFilters = [...columnFilters];
				updatedColumnFilters[existingFilterIndex].value = label === "Active TOR" ? "1" : "0";
				setColumnFilters(updatedColumnFilters);
			} else {
				// Add new filter with id: "tor_status"
				setColumnFilters((prevFilters) => [...prevFilters, { id: "tor_status", value: label === "Active TOR" ? "1" : "0" }]);
			}
		}
	};

	const generateFilterString = (filters) => {
		const filterString = filters
			.filter((filter) => filter.value !== null && filter.value !== undefined && filter.value.length !== 0)
			.map((filter) => {
				const operation = Asset_Table_Filter_Operations[filter.id];
				if (!operation) return "";

				return `${filter.id} ${operation} '${filter.value}'`;
			})
			.filter((filter) => filter !== "")
			.join(" and ");

		return filterString;
	};

	return (
		<div>
			<DataTable title="Assets" columns={columns} data={data?.data || []} rowCount={data?.total} isLoading={loading} columnFilters={columnFilters} onColumnFiltersChange={setColumnFilters} columnFilterFns={columnFilterModes} onColumnFilterFnsChange={setColumnsFilterModes} sorting={sorting} onSortingChange={setSorting} pagination={pagination} onPaginationChange={setPagination} filterConfigs={filterConfigs} toolbarConfigs={toolbarConfigs} />
			<DrawerPanel open={open} setOpen={setOpen} title="Filter">
				All Filters Here
			</DrawerPanel>
		</div>
	);
}

export default AssetTable;
