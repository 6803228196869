/* eslint-disable prettier/prettier */
import AssetTable from "../../components/AssetTable";

const Assets = () => {
	return (
		<div>
			<AssetTable />
		</div>
	);
};

export default Assets;
