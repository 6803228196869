// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { formatDateTime } from "@roambee/client-utility";
const Asset_Table_Columns = [
	{
		accessorKey: "client_asset_number",
		header: "Asset Number",
		isSortable: true,
		isSearchable: true,
		Cell: ({ renderedCellValue }) => (renderedCellValue ? renderedCellValue : "-")
	},
	{
		accessorKey: "name",
		header: "Asset Name",
		isSortable: true,
		isSearchable: true,
		Cell: ({ renderedCellValue }) => (renderedCellValue ? renderedCellValue : "-")
	},
	{
		accessorKey: "description",
		header: "Description",
		isSortable: true,
		isSearchable: true,
		Cell: ({ renderedCellValue }) => (renderedCellValue ? renderedCellValue : "-")
	},
	{
		accessorKey: "bee_name",
		header: "Bee Name",
		isSortable: true,
		isSearchable: true,
		Cell: ({ renderedCellValue }) => (renderedCellValue ? renderedCellValue : "-")
	},
	{
		accessorKey: "device_imei",
		header: "Device IMEI",
		isSortable: true,
		isSearchable: true,
		Cell: ({ renderedCellValue }) => (renderedCellValue ? renderedCellValue : "-")
	},
	{
		accessorKey: "beezone_label",
		header: "Beezone Label",
		isSortable: true,
		isSearchable: true,
		Cell: ({ renderedCellValue }) => (renderedCellValue ? renderedCellValue : "-")
	},
	{
		accessorKey: "parent_bee",
		header: "Latest Mapped Bee",
		isSortable: true,
		isSearchable: true,
		Cell: ({ renderedCellValue }) => (renderedCellValue ? renderedCellValue : "-")
	},
	{
		accessorKey: "account_name",
		header: "Account Name",
		isSortable: true,
		isSearchable: true,
		Cell: ({ renderedCellValue }) => (renderedCellValue ? renderedCellValue : "-")
	},
	{
		accessorKey: "geo",
		id: "last_known_time",
		header: "Last Known Time",
		isSortable: true,
		isSearchable: false,
		Cell: ({ renderedCellValue, row }) => {
			return formatDateTime(renderedCellValue ? JSON.parse(renderedCellValue).last_known_time : null, "LLL dd, yyyy hh:mm a", row?.original?.geo ? JSON.parse(row?.original?.geo).timezone?.timezoneId : null);
		}
	},
	{
		accessorKey: "geo",
		id: "geo_address",
		header: "Last Known Location",
		isSortable: true,
		isSearchable: false,
		Cell: ({ renderedCellValue }) => (renderedCellValue ? JSON.parse(renderedCellValue).geo_address : "-")
	},
	{
		accessorKey: "on_shipment",
		header: "On Shipment",
		isSortable: false,
		isSearchable: false,
		Cell: ({ renderedCellValue }) => renderedCellValue.charAt(0).toUpperCase() + renderedCellValue.slice(1)
	},
	{
		accessorKey: "seen",
		header: "Seen",
		isSortable: true,
		isSearchable: true,
		Cell: ({ renderedCellValue }) => (renderedCellValue ? renderedCellValue : "-")
	},
	{
		accessorKey: "age.first_seen",
		header: "Age",
		isSortable: false,
		isSearchable: false,
		Cell: ({ renderedCellValue }) => {
			return formatDateTime(renderedCellValue, "LLL dd, yyyy hh:mm a");
		}
	},
	{
		accessorKey: "first_seen_time_stamp",
		header: "Status Days",
		isSortable: true,
		isSearchable: false,
		Cell: ({ renderedCellValue }) => (renderedCellValue ? renderedCellValue : "-")
	},
	{
		accessorKey: "shipment_uuid",
		header: "Shipment ID",
		isSortable: true,
		isSearchable: false,
		Cell: ({ renderedCellValue }) => (renderedCellValue ? renderedCellValue : "-")
	},
	{
		accessorKey: "custom_fields",
		header: "Custom",
		isSortable: false,
		isSearchable: false,
		Cell: ({ renderedCellValue }) => `<div>Custom Fields</div>`
	},
	{
		accessorKey: "created_date",
		header: "Created Date",
		isSortable: true,
		isSearchable: false,
		Cell: ({ renderedCellValue }) => formatDateTime(renderedCellValue, "LLL dd, yyyy hh:mm a")
	},
	{
		accessorKey: "zone_count",
		header: "Zone History",
		isSortable: true,
		isSearchable: false,
		Cell: ({ renderedCellValue }) => (renderedCellValue ? renderedCellValue : "-")
	},
	{
		accessorKey: "dwell_time",
		header: "Dwell Time",
		isSortable: true,
		isSearchable: false,
		Cell: ({ renderedCellValue }) => (renderedCellValue ? renderedCellValue : "-")
	},
	{
		accessorKey: "sensor.battery.remaining_days",
		header: "Battery Days",
		isSortable: true,
		isSearchable: true,
		Cell: ({ renderedCellValue }) => renderedCellValue || "NA"
	},
	{
		accessorKey: "sensor.TMP.value",
		header: "Temperature (°C)",
		isSortable: true,
		isSearchable: true,
		Cell: ({ renderedCellValue }) => renderedCellValue || "NA"
	},
	{
		accessorKey: "sensor.ALT.value",
		header: "Altitude (m)",
		isSortable: true,
		isSearchable: true,
		Cell: ({ renderedCellValue }) => renderedCellValue || "NA"
	},
	{
		accessorKey: "sensor.AMB.value",
		header: "Ambient",
		isSortable: true,
		isSearchable: true,
		Cell: ({ renderedCellValue }) => renderedCellValue || "NA"
	},
	{
		accessorKey: "sensor.HUM.value",
		header: "Humidity",
		isSortable: true,
		isSearchable: true,
		Cell: ({ renderedCellValue }) => renderedCellValue || "NA"
	},
	{
		accessorKey: "sensor.PRS.value",
		header: "Pressure",
		isSortable: true,
		isSearchable: true,
		Cell: ({ renderedCellValue }) => renderedCellValue || "NA"
	},
	{
		accessorKey: "sensor.TLT.value",
		header: "Tilt",
		isSortable: true,
		isSearchable: true,
		Cell: ({ renderedCellValue }) => renderedCellValue || "NA"
	},
	{
		accessorKey: "tor_status",
		header: "TOR Status",
		isSortable: true,
		isSearchable: true,
		Cell: ({ renderedCellValue }) => (renderedCellValue === 0 ? "false" : renderedCellValue === 1 ? "true" : "NA")
	}
];

const Asset_Table_Filter_Operations = {
	client_asset_number: "has",
	name: "has",
	description: "has",
	bee_name: "has",
	device_imei: "has",
	beezone_label: "has",
	parent_bee: "has",
	account_name: "has",
	"geo.last_known_time": "has",
	"geo.geo_address": "has",
	on_shipment: "has",
	seen: "has",
	"age.first_seen": "has",
	first_seen_time_stamp: "has",
	shipment_uuid: "has",
	custom_fields: "has",
	created_date: "has",
	zone_count: "has",
	dwell_time: "has",
	"sensor.battery.remaining_days": "has",
	"sensor.TMP.value": "has",
	"sensor.ALT.value": "has",
	"sensor.AMB.value": "has",
	"sensor.HUM.value": "has",
	"sensor.PRS.value": "has",
	"sensor.TLT.value": "has",
	tor_status: "eq",
	asset_type: "eq"
};

export { Asset_Table_Columns, Asset_Table_Filter_Operations };
